import React, { forwardRef, useEffect, useState } from 'react';
import { Select, Spin, Switch } from 'antd';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_LOCATION_SIMPLE } from 'queries/LocationQueries';
import { LocationStatuses } from '../../constants/LocationStatuses';

const { Option } = Select;

const LocationSearchSelect = forwardRef(
  ({ allOption, children, disableChildren, ...props }: any, ref: any) => {
    const [activeOnly, setActiveOnly] = useState(true);
    const [where, setWhere] = useState({});
    const baseVariables = {
      pagination: {
        size: 100,
        page: 1,
      },
    };
    const {
      data: locations,
      loading,
      error,
      refetch,
    } = useQuery(QUERY_LIST_LOCATION_SIMPLE, {
      variables: {
        order: [{ field: 'name', order: 'ASC' }],
        where,
        pagination: baseVariables.pagination,
      },
    });

    useEffect(() => {
      if (activeOnly) {
        setWhere({ ...where, status_id: { in: [LocationStatuses.Active] } });
      } else {
        setWhere({ ...where, status_id: undefined });
      }
    }, [activeOnly]);

    const fetchLocations = debounce(async (value: string) => {
      refetch({
        ...baseVariables,
        where: { ...where, name: { contains: value } },
      });
    }, 800);

    return (
      <Select
        ref={ref}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        showSearch
        onSearch={fetchLocations}
        style={{ width: '100%' }}
        dropdownRender={(menu) => (
          <div>
            <div style={{ padding: 8, borderBottom: '1px solid #f0f0f0' }}>
              <span style={{ fontWeight: 600 }}>All Locations</span>
              <Switch
                checked={activeOnly}
                onChange={setActiveOnly}
                style={{ margin: '0 10px' }}
              />
              <span style={{ fontWeight: 600 }}>Active Only</span>
            </div>
            {menu}
          </div>
        )}
        {...props}
      >
        {allOption ? (
          <Option key="all" value="all">
            - All -
          </Option>
        ) : null}

        {children}

        {!disableChildren && locations && !error
          ? locations.listLocation.list.map((l: any) => (
              <Option key={l.location_id} value={`${l.location_id}`}>
                {l.name}
              </Option>
            ))
          : null}
      </Select>
    );
  },
);

LocationSearchSelect.displayName = 'LocationSearchSelect';

export default LocationSearchSelect;
