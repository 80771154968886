import gql from 'graphql-tag';

export const QUERY_LIST_USER_SIMPLE = gql`
  query UserListSimple(
    $where: WhereUserInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listUser(where: $where, pagination: $pagination, order: $order) {
      total
      list {
        user_id
        role_id
        email
        full_name
        userCorporateClients {
          corporate_client_id
          corporateClient {
            name
          }
        }

        current_client_level {
          membershipLevel {
            name
          }
          corpClient: corporateClient {
            name
          }
        }
      }
    }
  }
`;

export const QUERY_LIST_USERS = gql`
  query ListUsers(
    $where: WhereUserInput
    $order: [OrderInput!]
    $pagination: PaginationInput
  ) {
    listUser(where: $where, order: $order, pagination: $pagination) {
      total
      list {
        user_id
        role_id
        email
        full_name
        first_name
        last_name
        google_place_id
        last_purge_id
        adminRole {
          name
        }

        permissions {
          permission_id
          name
        }

        locationRoles {
          location_role_id
          disabled_on

          location {
            location_id
            name
            pretty_name
          }

          role {
            role_id
            name
          }
        }

        current_client_level {
          membershipLevel {
            name
          }
          corpClient: corporateClient {
            name
          }
          clientRole {
            name
          }
        }

        payment_type
        payment_promo_code

        userCorporateClients {
          corporate_client_id
          corporateClient {
            name
          }
        }

        user_membership {
          to
          more_info
        }
      }
    }
  }
`;

export const QUERY_GET_USER = gql`
  query GetUser($user_id: ID!) {
    getUser(user_id: $user_id) {
      user_id
      role_id
      email
      full_name
      first_name
      last_name
      stripe_customer_id

      firebase_user_id
      google_place_id
      last_purge_id

      permissions {
        permission_id
        name
      }

      userCorporateClients {
        corporate_client_id
      }

      current_client_level {
        membershipLevel {
          name
        }
        corporateClient {
          name
        }
      }

      user_membership {
        to
        paymentProcessor {
          name
        }
        locked_by_transaction_id
      }

      userPurges {
        purged_on
      }
    }
  }
`;

export const QUERY_GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferences($user_id: ID!) {
    getNotificationPreferences(user_id: $user_id) {
      prefs
    }
  }
`;

export const MUTATION_REMOVE_USER = gql`
  mutation RemoteUser($user_id: ID!) {
    removeUser(user_id: $user_id)
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser($user_id: ID!, $input: UserInput!) {
    updateUser(user_id: $user_id, input: $input) {
      success
      error_message {
        message
        type
      }
    }
  }
`;

export const MUTATION_USER_CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($user_id: ID!) {
    adminCancelSubscription(user_id: $user_id)
  }
`;

export const MUTATION_UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation UpdateNotificationPreferences(
    $user_id: ID!
    $input: NotificationPreferencesInput!
  ) {
    updateNotificationPreferences(user_id: $user_id, input: $input) {
      user_id
    }
  }
`;

export const MUTATION_REMOVE_USER_FROM_STRIPE = gql`
  mutation RemoveFromStripe($stripe_customer_id: String!) {
    removeFromStripe(stripe_customer_id: $stripe_customer_id) {
      success
    }
  }
`;

export const MUTATION_REMOVE_USER_FROM_MARKETING = gql`
  mutation RemoveFromMarketing($email: String!) {
    removeFromMarketing(email: $email) {
      success
    }
  }
`;

export const MUTATION_REMOVE_USER_FROM_FIREBASE = gql`
  mutation RemoveFromFirebase($firebase_user_id: String!) {
    removeFromFirebase(firebase_user_id: $firebase_user_id) {
      success
    }
  }
`;

export const MUTATION_REMOVE_USER_FROM_DB = gql`
  mutation RemoveFromDB($user_id: ID!) {
    removeFromDB(user_id: $user_id)
  }
`;

export const MUTATION_VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const MUTATION_UNFREEZE_USER = gql`
  mutation UnfreezeUser($user_id: ID!) {
    unfreezeUser(user_id: $user_id) {
      success
      error_message {
        message
      }
    }
  }
`;

export const MUTATION_INVITE_USER = gql`
  mutation CreateInvite(
    $adminRoleId: Int!
    $grantedPermissions: [Int!]!
    $inviteeEmail: String!
    $corporateClientId: Int!
  ) {
    createInvite(
      admin_role_id: $adminRoleId
      granted_permissions: $grantedPermissions
      invitee_email: $inviteeEmail
      corporate_client_id: $corporateClientId
    ) {
      isSuccess
      message
    }
  }
`;

export const MUTATION_ACCEPT_INVITE = gql`
  mutation AcceptInvite(
    $last_name: String!
    $first_name: String!
    $invitee_uuid: String!
    $firebase_user_id: String!
  ) {
    acceptInvite(
      last_name: $last_name
      first_name: $first_name
      invitee_uuid: $invitee_uuid
      firebase_user_id: $firebase_user_id
    ) {
      isSuccess
      message
    }
  }
`;

export const GET_ADMIN_ROLES = gql`
  query GetAdminRoles($corporate_client_id: Int) {
    getAdminRoles(corporate_client_id: $corporate_client_id) {
      role_id
      name
      description
      permissions {
        permission_id
        name
      }
      isEnabled
    }
  }
`;

export const GET_EXISTING_AND_INVITED_ADMIN_USERS = gql`
  query GetExistingAndInvitedAdminUsers($corporateClientId: Int!) {
    getExistingAndInvitedAdminUsers(corporate_client_id: $corporateClientId) {
      user_id
      roleName
      email
      status
      firstName
      lastName
      invitedOn
      permissions
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation ResendInvite($userEmail: String!, $corporateClientId: Int!) {
    resendInvite(
      user_email: $userEmail
      corporate_client_id: $corporateClientId
    ) {
      isSuccess
      message
    }
  }
`;

export const REVOKE_INVITE = gql`
  mutation RevokeInvite($userEmail: String!, $corporateClientId: Int!) {
    revokeInvite(
      user_email: $userEmail
      corporate_client_id: $corporateClientId
    ) {
      isSuccess
      message
    }
  }
`;

export const REMOVE_ADMIN_PERMISSIONS = gql`
  mutation RemoveAdminAccess($userId: Int!, $corporateClientId: Int!) {
    removeAdminAccess(
      user_id: $userId
      corporate_client_id: $corporateClientId
    ) {
      isSuccess
      message
    }
  }
`;
