import gql from 'graphql-tag';

export const QUERY_LIST_LOCATION_SIMPLE = gql`
  query LocationListSimple(
    $where: WhereLocationInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listLocation(
      where: $where
      pagination: $pagination
      order: $order
      isAdmin: true
    ) {
      total
      list {
        location_id
        name
        status_id
      }
    }
  }
`;
